import { cn } from '@/app/utils'
import { validatePartial } from '@/app/utils/validatePartial'
import { useSession } from '@/auth/session.hook'
import { ClientDataSchema, Message } from '@/backend/models/Message'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useConversation } from '../hooks/useConversations'
import { useMessengerState } from '../hooks/useMessengerState'
import { message, useInsertMessage } from '../hooks/useWebSocket'
import { clientDataForms } from './ClientDataForms'

type ClientData = z.infer<typeof ClientDataSchema>

export function useClientData({
  messageCount,
  conversationId,
  ...props
}: {
  conversationId: string | null
  messageCount: number
  message?: Message
  backdropTop: number
}) {
  const session = useSession()
  const conversation = useConversation()

  const clientData = useMemo(() => {
    const { message } = props

    if (!message) return null
    if (message.type !== 'client-data') return null

    try {
      return ClientDataSchema.parse(JSON.parse(message.content))
    } catch {
      return null
    }
  }, [props])

  const qc = useQueryClient()
  const messagesData = qc.getQueryData(['messages', conversationId])

  const [condition, formfilled] = useMemo(() => {
    if (session.status !== 'authenticated') return [false, false] as const
    if (!conversation.data) return [false, false] as const
    if (conversation.data.isAgency) return [false, false] as const
    if (!messagesData) return [false, false] as const

    const isCurrentUserInitiator = conversation.data.isCurrentUserInitiator

    return [
      messageCount < 2 && isCurrentUserInitiator,
      messageCount === 2 && isCurrentUserInitiator,
    ] as const
  }, [messagesData, messageCount, session, conversation.data])

  const component = (
    <ClientDataC
      filled={formfilled}
      key={conversationId + (props.message?.content || '')}
      {...props}
      clientData={clientData}
    />
  )

  return {
    condition,
    formfilled,
    component,
  }
}

function ClientDataC(props: {
  filled: boolean
  message?: Message
  backdropTop: number
  clientData: ClientData | null
}) {
  const [{ selectedConversationId: conversationId, isWssReady }] =
    useMessengerState()
  const [step, setStep] = useState(0)
  const session = useSession()

  const form = useForm<ClientData>({
    resolver: zodResolver(ClientDataSchema),
    defaultValues: props.clientData || {
      name: '',
      product: '',
      date: new Date(),
      information: '',
      contact: '',
    },
  })

  const values = form.watch()

  const CDForm = clientDataForms[step]

  const shouldDisableSubmit = useMemo(() => {
    if (step === 0) return false
    if (step === 1) {
      const validate = validatePartial(ClientDataSchema, [
        'name',
        'product',
        'date',
        'information',
      ])

      return !validate(values)
    }

    return form.formState.isSubmitting || !form.formState.isValid || !isWssReady
  }, [form.formState, values, step, isWssReady])

  const insertMessage = useInsertMessage()

  const handleSubmit = () => {
    if (step === 0) {
      setStep(1)

      return
    }

    if (step === 1) {
      setStep(2)

      return
    }

    form.handleSubmit((data) => {
      if (session.status === 'unauthenticated') return

      const msg = {
        id: props.clientData ? props.message?.id || nanoid() : nanoid(),
        conversationId: conversationId!,
        userId: session.data!.user.id,
        type: 'client-data' as const,
        content: JSON.stringify(data),
        isRead: false,
      }

      insertMessage(msg)
      message(msg)
    })()
  }

  const handleBack = () => {
    if (step === 0) return
    setStep(step - 1)
  }

  const handleClose = () => {
    setStep(0)
  }

  return (
    <FormProvider {...form}>
      {!props.filled && (
        <div
          style={{
            top: `${props.backdropTop}px`,
            height: `calc(100% - ${props.backdropTop}px)`,
          }}
          className="absolute w-full bg-[#00000033]"
        />
      )}
      <div
        className={cn('relative rounded-t-20 bg-white', {
          'shadow-minus-y': props.filled,
        })}>
        <CDForm
          filled={props.filled}
          disableSubmit={shouldDisableSubmit}
          onClose={handleClose}
          onBack={handleBack}
          onSubmit={handleSubmit}
        />
      </div>
    </FormProvider>
  )
}
